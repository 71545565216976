<template>
  <b-link
    class="mobile-chat-trigger p-2"
    @click.prevent.stop="toggle"
  >
    <font-awesome-icon size="lg" :icon="['fas', 'comment']" />
  </b-link>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MobileChatTrigger',
  ...mapGetters({
    nav: 'nav/nav',
    talksListCount: 'chat/talksListCount',
    talksListUnread: 'chat/talksListUnread',
    talksListState: 'chat/talksListState'
  }),
  methods: {
    ...mapActions({
      loadTalks: 'chat/loadTalks',
      showTalksList: 'chat/showTalksList',
      hideTalksList: 'chat/hideTalksList'
    }),
    toggle () {
      if (!this.talksListState) {
        this.showTalksList()
      } else {
        this.hideTalksList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
