<template>
  <nav
    id="sidebar"
    class="nav-sidebar"
    :class="{'hidden': !sidebarState}"
  >
    <div class="sidebar-header d-flex flex-row align-items-center" @click="() => { sidebarState = !sidebarState }">
      <b-img :src="logo" alt="HostessWing" height="38" />
      <h5 class="label pl-2">
        {{ $t('appTitle') }}
      </h5>
    </div>

    <sidebar-nav
      :key="key"
      class="sidebar-body"
      :items="navItems"
    />

    <div class="sidebar-footer">
      <ul
        class="list-unstyled m-0"
        :class="{'show': sidebarState}"
      >
        <li style="position: relative" :class="{'animate': supportChatAnimation}">
          <a
            :class="talksListCount === 0 ? 'disabled' : ''"
            class="bo-nav-link text-nowrap cursor-pointer"
            style="position: relative"
            @click.stop.prevent="showTalksList"
          >
            <font-awesome-icon
              class=""
              :icon="['fas', 'comment']"
            />
            <span
              v-if="talksListUnread > 0"
              class="badge rounded-pill badge-notification bg-danger support-badge"
            >
              {{ talksListUnreadDisplay }}
            </span>
            <span class="label pl-3">
              {{ $t('n_support_chat') }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters } from 'vuex'
import logo from '~/static/logo.png'
export default {
  name: 'Sidebar',
  data () {
    return {
      logo,
      key: 0,
      supportChatAnimation: false
    }
  },
  computed: {
    ...mapGetters({
      nav: 'nav/nav',
      talksListCount: 'chat/talksListCount',
      talksListUnread: 'chat/talksListUnread'
    }),
    ...mapFields({
      sidebarState: 'layout.sidebarState'
    }),
    userRoles () {
      return this.$auth && this.$auth.user && this.$auth.user.roles
    },
    navItems () {
      // eslint-disable-next-line no-unused-expressions
      this.key
      return this.nav.filter(item => this.$gates.hasAnyRole(item.roles)) || []
    },
    talksListUnreadDisplay () {
      return this.talksListUnread < 100 ? this.talksListUnread : '99+'
    }
  },
  watch: {
    userRoles: {
      handler (n) {
        this.key += 1
      },
      deep: true
    },
    talksListUnread (n, o) {
      if (n > o) {
        this.supportChatAnimation = true
        setTimeout(() => {
          this.supportChatAnimation = false
        }, 5000)
      } else {
        this.supportChatAnimation = false
      }
    }
  },
  mounted () {
    this.loadTalks()
  },
  methods: {
    ...mapActions({
      loadTalks: 'chat/loadTalks',
      showTalksList: 'chat/showTalksList',
      hideTalksList: 'chat/hideTalksList'
    })
  }
}
</script>

<style lang="scss">
</style>
