<template>
  <div
    class="mobile-nav"
    :class="{'hidden': !sidebarState}"
  >
    <div class="mobile-nav__header w-100 py-3 px-3 d-inline-flex align-items-center justify-content-between">
      <b-link class="py-1 px-2" @click="sidebarState = false">
        <font-awesome-icon :icon="['fas', 'xmark']" size="lg" />
      </b-link>
      <div class="mobile-nav__title py-1 px-2 d-flex flex-row">
        <nuxt-link to="profile" class="small">
          <font-awesome-icon :icon="['fas', 'user-shield']" size="lg" class="mr-2" />
          {{ user.email }}
        </nuxt-link>
      </div>
    </div>
    <div class="mobile-nav__body px-2 py-2">
      <sidebar-nav
        :key="key"
        class="sidebar-body"
        :items="navItems"
        :tooltips="false"
      />
    </div>
    <div class="mobile-nav__footer">
      <b-link class="d-block w-100 p-3 px-4" @click="signOut">
        {{ $t('eUserNav_signOut') }}
      </b-link>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'MobileNav',
  data () {
    return {
      key: 0
    }
  },
  computed: {
    ...mapGetters({
      nav: 'nav/nav',
      talksListCount: 'chat/talksListCount',
      talksListUnread: 'chat/talksListUnread'
    }),
    ...mapFields({
      sidebarState: 'layout.sidebarState'
    }),
    user () {
      return this.$auth && this.$auth.user
    },
    userRoles () {
      return this.$auth && this.$auth.user && this.$auth.user.roles
    },
    navItems () {
      // eslint-disable-next-line no-unused-expressions
      this.key
      return this.nav.filter(item => this.$gates.hasAnyRole(item.roles)) || []
    },
    talksListUnreadDisplay () {
      return this.talksListUnread < 100 ? this.talksListUnread : '99+'
    }
  },
  watch: {
    '$route.path': {
      handler (n) {
        this.sidebarState = false
      },
      deep: true
    },
    userRoles: {
      handler (n) {
        this.key += 1
      },
      deep: true
    }
  },
  methods: {
    signOut () {
      Cookie.set('auth.redirect', this.$route.fullPath)
      this.$auth.logout()
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-nav {
  //
}
</style>
