<template>
  <li :style="listElementStyle">
    <b-link
      v-if="!hasChilds && userAccess"
      :id="item.title"
      :title="$t(item.title)"
      :disabled="!state"
      class="bo-nav-link text-nowrap"
      :class="isItemActive ? 'nuxt-link-active' : ''"
      @click.stop.prevent="go(item.path)"
    >
      <font-awesome-icon
        v-if="item.icon"
        :icon="['fas', item.icon]"
        class="mr-2"
      />
      <span class="label text-nowrap">{{ $t(item.title) }}</span>
    </b-link>
    <b-link
      v-if="hasChilds && userAccess"
      :id="item.title"
      ref="link"
      :title="$t(item.title)"
      :aria-expanded="expandedState"
      class="dropdown-toggle text-nowrap"
      :class="isItemActive ? 'nuxt-link-active' : ''"
      data-toggle="collapse"
      :disabled="!state"
      @click.stop.prevent="toggleSubNav"
    >
      <font-awesome-icon
        v-if="item.icon"
        :icon="['fas', item.icon]"
        class="mr-2"
      />
      <span class="label text-nowrap">{{ $t(item.title) }}</span>
    </b-link>
    <sidebar-nav
      v-if="hasChilds && userAccess"
      :items="item.childs"
      :is-sub-menu="true"
      :is-expanded="expandedState"
      :class="subNavElementClass"
      :tooltips="tooltips"
    />
    <b-tooltip
      v-if="userAccess && tooltips"
      :target="item.title"
      placement="right"
      variant="secondary"
    >
      {{ $t(item.title) }}
    </b-tooltip>
  </li>
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarNavItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    tooltips: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      expandedState: false,
      state: !!this.item.isEnabled
    }
  },
  computed: {
    ...mapGetters({
      activeIdx: 'nav/activeIdx',
      isActiveFn: 'nav/isActiveFn',
      chain: 'repos/chain'
    }),
    ...mapFields({
      sidebarState: 'layout.sidebarState'
    }),
    isItemActive () {
      return this.isActiveFn(this.item.idx)
    },
    hasChilds () {
      return this.item.childs && this.item.childs.length
    },
    listElementStyle () {
      return this.hasChilds && !this.sidebarState ? 'position: relative' : ''
    },
    subNavElementClass () {
      return this.hasChilds && !this.sidebarState ? 'bo-nav-sub-item' : ''
    },
    userAccess () {
      if (this.item) {
        const roles = this.item && this.item.roles.split('|')
        if (roles.length) {
          if (roles.includes('private') && this.$auth && this.$auth?.user?.is_private_user) {
            return true
          } else if (this.$gates.hasAnyRole(this.item.roles)) {
            return true
          }
        } else {
          return true
        }
      }
      return false
    }
  },
  watch: {
    activeIdx () {
      if (this.expandedState) {
        this.expandedState = false
      }
    },
    sidebarState (n) {
      if (n) {
        this.$root.$emit('bv::disable::tooltip', this.item.title)
      } else {
        this.$root.$emit('bv::enable::tooltip', this.item.title)
      }
    },
    expandedState (n) {
      if (n) {
        this.$root.$emit('bv::hide::tooltip', this.item.title)
        this.$root.$emit('bv::disable::tooltip', this.item.title)
      } else {
        this.$root.$emit('bv::enable::tooltip', this.item.title)
        this.$root.$emit('bv::show::tooltip', this.item.title)
      }
    },
    chain: {
      handler (n) {
        // Show nav for `CustomerEditForm` hardcode
        const chain = n?.filter(i => i.name !== 'CustomerEditForm') || []
        if (chain.length > 0) {
          this.state = false
        } else {
          this.state = true
        }
      },
      deep: true
    }
  },
  methods: {
    toggleSubNav (e) {
      this.expandedState = !this.expandedState
    },
    onActiveInside () {
      this.expandedState = true
    },
    go (to) {
      this.$router.push(to)
    }
  }
}
</script>

<style lang="scss">
@import "assets/css/variables";
.bo-nav-sub-item {
  position: absolute;
  left: $sidebarPreviewWidth;
  top: 0;
  width: $sidebarPreviewWidth;
}
.bo-nav-link.disabled, .dropdown-toggle.disabled {
  cursor: default;
  opacity: 0.7;
}
</style>
