<template>
  <ul
    class="list-unstyled"
    :class="{'components': !isSubMenu, 'collapse': isSubMenu, 'show': isExpanded}"
  >
    <sidebar-nav-item
      v-for="(item, i) in items"
      :key="i"
      :item="item"
      :tooltips="tooltips"
    />
  </ul>
</template>

<script>
// import SidebarNavItem from './SidebarNavItem'
export default {
  name: 'SidebarNav',
  // components: { SidebarNavItem },
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    isSubMenu: {
      type: Boolean,
      default: false
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    tooltips: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
</style>
