<template>
  <b-link
    class="mobile-nav-trigger p-2"
    @click="sidebarState = !sidebarState"
  >
    <font-awesome-icon size="lg" :icon="['fas', 'bars']" />
  </b-link>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'MobileNavTrigger',
  computed: {
    ...mapFields({
      sidebarState: 'layout.sidebarState'
    })
  }
}
</script>

<style lang="scss" scoped>
.mobile-nav-trigger {
  //
}
</style>
